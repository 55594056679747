import React from "react"
import { graphql, Link } from "gatsby"
import { Hero } from "../components/StandartPage/index"
import kebabCase from "lodash/kebabCase"
import Seo from "../components/seo"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const tag = frontmatter.tags
  return (
    <>
      <Seo title={frontmatter.title} description={frontmatter.description} />
      <Hero title={frontmatter.title} subtitle={frontmatter.date} />
      <div className="section">
        <div className="wrap" dangerouslySetInnerHTML={{ __html: html }} />
        <div>
          <div className="tags">
            <h4 className="center">ETİKETLER</h4>
            <ul className="wrap">
              {tag.map((tag, i) => (
                <li key={i}>
                  <Link className="btn nobg" to={`/tags/${kebabCase(tag)}/`}>
                    {tag}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        tags
        description
      }
    }
  }
`
